import { createContext } from "react";

const defaultTheme = {
  primary: "#8C142D",
  secondary: "#CAB5B5",
  background: "#06060A",
  border: "#5A5A62",
  backGrad1: "#06060A",
  backGrad2: "#171722",
  backGrad3: "#221c29",
};

const ThemeContext = createContext(defaultTheme);

function ThemeProvider(props) {
  return <ThemeContext.Provider value={defaultTheme} {...props} />;
}

export { ThemeContext, ThemeProvider };
