import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { motion } from "framer-motion";

import { ThemeContext } from "./context/theme";

import NavBar from "./components/NavBar";

import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";

export default function App() {
  const theme = useContext(ThemeContext);

  return (
    <div
      name="BaseBackground"
      style={{ background: theme.background, height: "100vh" }}
    >
      <Router>
        <NavBar />
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/loginregister" component={LoginPage} />
      </Router>
    </div>
  );
}
