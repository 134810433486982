import React from "react";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "apollo-link-context";

//Tell Apollo where to send the GraphQL request
const httpLink = createHttpLink({ uri: "http://localhost:5000" });

//Attach authorization token to the url if there is one by setting up headers for the request
const authLink = setContext((req, pre) => {
  const token = localStorage.getItem("jwtToken");
  return { headers: { Authorization: token ? `Bearer ${token}` : "" } };
});

//Create the client for the provider to use
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
