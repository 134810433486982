import React, { useContext } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ThemeContext } from "../../context/theme";

export default function BlurbCarousel() {
  const theme = useContext(ThemeContext);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    dotsClass: "blurb-dots",
    appendDots: (dots) => {
      return (
        <div
          style={{
            background: "#23232E",
            paddingBottom: 10,
            borderBottom: `2px solid #5A5A62`,
          }}
        >
          <ul>{dots}</ul>
        </div>
      );
    },
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const blurbs = [
    {
      title: "Modern Tabletop RPG",
      body:
        "Soul Forge is a Tabletop Role Playing Game that utilizes stats, dice, and player ingenuity to face challenges, create narratives, and forge experiences with a group of friends. Build unique and memorable characters to explore a world where the possibilities are endless.",
    },
    {
      title: "Cooperative Storytelling",
      body:
        "Character development is the central focus of Soul Forge. The gameplay and character building is designed to facilitate unique and meaningful character progression according to the events of their journey. The choices made by characters will affect your character and the world around them.",
    },
    {
      title: "Worldbuilding Friendly",
      body:
        "The world you create is not confined to a single genre, time period, or fantasy. Soul Forge is designed to be compatible with any setting you wish to create and explore.",
    },
    {
      title: "Diverse Gameplay",
      body:
        "Soul Forge supports a wide variety of playstyles and adventures. Whether you want to explore the world, battle tough foes, or start a trade empire, you can find mechanics that make those campaigns both deep and interesting.",
    },
    {
      title: "Character Progression",
      body:
        "Leveling is not dependent on combat or success, it depends entirely on your characters experience and learning. As your character gains new experiences, you are free to add or remove any element from your character as they change. This creates a more natural and compelling environment for storytelling.",
    },
    {
      title: "Strategic Combat",
      body:
        "Combat is never a grind for players as strategy and teamwork take center stage. Just as your character has Edges and Spells, so can your enemies. Combat depends a lot on learning how to fight against your enemies strategies as opposed to relying solely on chance. Even the most powerful of enemies can be overcome with the right strategy.",
    },
    {
      title: "Balanced Chance",
      body:
        "Success and failure are dependent on your dice rolls during a session. However, your characters experience translates directly into improving their odds at success. Instead of relying on an arms race of +1, Soul Forge encourages more creative routes of progression.",
    },
    {
      title: "Unique Character Creation",
      body:
        "Building a character in Soul Forge begins with personality and background. How the player wants to represent their character is informed by the Edges and Complications that character has. This process leads to some really fun and interesting characters that approach obstacles in vastly different ways.",
    },
    {
      title: "Unique Spells and Items",
      body:
        "Crafting, Enchanting, and Spellcraft are all available to Soul Forge characters through a component system. As your character improves, they can add more interesting and powerful components to their creations. Characters can build entirely unique spells, weapons, armors, consumables, and even contraptions",
    },
    {
      title: "Homebrew Friendly",
      body:
        "Character elements and gameplay mechanics are built with homebrew in mind. Creating or altering content is both easy and simple to integrate into Soul Forge.",
    },
  ];

  const blurbSlides = blurbs.map((blurb) => {
    return (
      <div tabIndex="-1" style={{ paddingBottom: 20 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 70,
            background: theme.background,
            padding: 10,
            borderBottom: `2px solid #5A5A62`,
          }}
        >
          <h2
            style={{
              color: theme.secondary,
              fontFamily: "Spectral SC",
              fontWeight: 500,
              fontSize: 32,
              textAlign: "center",
              lineHeight: "100%",
            }}
          >
            {blurb.title}
          </h2>
        </div>
        <p
          style={{
            background: "#23232E",
            color: "white",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
            paddingBottom: 30,
            textAlign: "center",
            fontFamily: "EB Garamond",
            fontWeight: 500,
          }}
        >
          {blurb.body}
        </p>
      </div>
    );
  });

  return (
    <div style={{ width: "100%", background: "#23232E" }}>
      <Slider {...settings}>{blurbSlides}</Slider>
    </div>
  );
}
