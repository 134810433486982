import React, { useContext } from "react";
import styled from "styled-components";

import { ThemeContext } from "../../context/theme";

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.primary};
  padding: 5px;
  margin-top: 3px;
  min-width: 200px;
  width: 100%;
`;

export default function LoginAuthError({ children }) {
  const theme = useContext(ThemeContext);
  return <ErrorMessage theme={theme}>{children}</ErrorMessage>;
}
