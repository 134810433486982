import React from "react";
import styled from "styled-components";

const Block = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  max-width: 300px;
`;

const Header = styled.h1`
  color: white;
  text-decoration: none;
  font-size: 24;
  font-family: Spectral SC;
  font-weight: 500;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const InputDiv = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: center;
`;

export default function LoginForm(props) {
  const { header, onSubmit } = props;

  const children = props.children.map((child) => {
    return <InputDiv>{child}</InputDiv>;
  });

  return (
    <Block>
      <Header>{header}</Header>
      <Form onSubmit={onSubmit}>{children}</Form>
    </Block>
  );
}
