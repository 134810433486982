import React, { useContext } from "react";

import { motion } from "framer-motion";
import { ReactComponent as Logo } from "../../assets/smallLogo.svg";

import { ThemeContext } from "../../context/theme";

export default function LandingCall() {
  const theme = useContext(ThemeContext);

  return (
    <div
      style={{ background: theme.background, paddingTop: 80, width: "100%" }}
    >
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            color: theme.secondary,
            fontFamily: "Spectral SC",
            fontWeight: 500,
            fontSize: 60,
            zIndex: 1,
            textAlign: "center",
          }}
        >
          Upcoming Beta!
        </h1>
        <p
          style={{
            color: "white",
            textAlign: "center",
            fontFamily: "EB Garamond",
            fontWeight: 500,
            padding: 20,
            zIndex: 1,
            maxWidth: 400,
          }}
        >
          Spellbreaker is preparing for a series of beta tests for the Soul
          Forge system itself as well as our online supplements. Keep your eyes
          and ears peeled for more information as it becomes available.
        </p>
        <Logo
          style={{
            position: "absolute",
            height: "260%",
            opacity: "25%",
            zIndex: 0,
          }}
        />
      </div>
    </div>
  );
}
