import React, { useContext, useState } from "react";
import styled from "styled-components";
import gql from "graphql-tag";
import { useForm } from "../../util/hooks";
import { useMutation } from "@apollo/client";

import { ThemeContext } from "../../context/theme";
import { AuthContext } from "../../context/auth";
import LoginForm from "./LoginForm";

import Button from "../../components/Button";
import TextInput from "./InputField";
import LoginAuthError from "./LoginAuthError";

const Doc = styled.div`
  background: rgb(6, 6, 10);
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.backGrad1} 0%,
    ${({ theme }) => theme.backGrad2} 50%,
    ${({ theme }) => theme.backGrad3} 100%
  );
  border-bottom: 2px solid ${({ theme }) => theme.border};
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export default function LoginPage(props) {
  const theme = useContext(ThemeContext);

  const context = useContext(AuthContext);

  //-----LOGIN-----
  const [loginErrors, setLoginErrors] = useState({});

  const {
    onSubmit: onLoginSubmit,
    onChange: onLoginChange,
    values: loginValues,
  } = useForm(loginFn, {
    usernameOrEmail: "",
    password: "",
  });

  const [loginUser, { loading: loginLoading }] = useMutation(LOGIN_USER, {
    variables: {
      emailOrUsername: loginValues.usernameOrEmail,
      password: loginValues.password,
    },
    update(proxy, { data: { loginUser: userData } }) {
      context.login(userData);
      props.history.push("/");
    },
    onError(err) {
      setLoginErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
  });

  function loginFn() {
    loginUser();
  }

  //-----REGISTER-----
  const [registerErrors, setRegisterErrors] = useState({});

  const {
    onSubmit: onRegisterSubmit,
    onChange: onRegisterChange,
    values: registerValues,
  } = useForm(registerFn, {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [registerUser, { loading: registerLoading }] = useMutation(
    REGISTER_USER,
    {
      variables: {
        ...registerValues,
      },
      update(proxy, { data: { registerUser: userData } }) {
        context.login(userData);
        props.history.push("/");
      },
      onError(err) {
        setRegisterErrors(err.graphQLErrors[0].extensions.exception.errors);
      },
    }
  );

  function registerFn() {
    registerUser();
  }

  return (
    <Doc name="Doc" theme={theme}>
      <LoginForm header="Login" onSubmit={onLoginSubmit}>
        {loginErrors.authentication && (
          <LoginAuthError>{loginErrors.authentication}</LoginAuthError>
        )}
        <TextInput
          name="usernameOrEmail"
          label="Username Or Email"
          placeholder="Username..."
          onChange={onLoginChange}
          value={loginValues.usernameOrEmail}
          errors={[loginErrors.username]}
        />
        <TextInput
          keyId="password"
          name="password"
          label="Password"
          placeholder="Password..."
          type="password"
          onChange={onLoginChange}
          errors={[loginErrors.password]}
        />
        <Button fontSize="12px" verticalPadding="5px" horizontalPadding="20px">
          Login
        </Button>
      </LoginForm>
      {
        <LoginForm header="Register" onSubmit={onRegisterSubmit}>
          <TextInput
            name="username"
            label="Username"
            placeholder="Username..."
            onChange={onRegisterChange}
            value={registerValues.username}
            errors={[registerErrors.username]}
          />
          <TextInput
            name="email"
            label="Email"
            placeholder="Email..."
            onChange={onRegisterChange}
            value={registerValues.email}
            errors={[registerErrors.email]}
          />
          <TextInput
            name="password"
            label="Password"
            placeholder="Password..."
            type="password"
            onChange={onRegisterChange}
            value={registerValues.password}
            errors={[registerErrors.password]}
          />
          <TextInput
            name="confirmPassword"
            label="Confirm Password"
            placeholder="Password..."
            type="password"
            onChange={onRegisterChange}
            value={registerValues.confirmPassword}
            errors={[registerErrors.confirmPassword]}
          />
          <Button
            fontSize="12px"
            verticalPadding="5px"
            horizontalPadding="20px"
            style={{ marginTop: 20 }}
          >
            Login
          </Button>
        </LoginForm>
      }
    </Doc>
  );
}

const LOGIN_USER = gql`
  mutation login($emailOrUsername: String!, $password: String!) {
    loginUser(emailOrUsername: $emailOrUsername, password: $password) {
      id
      username
      email
      token
    }
  }
`;

const REGISTER_USER = gql`
  mutation register(
    $username: String!
    $password: String!
    $confirmPassword: String!
    $email: String!
  ) {
    registerUser(
      username: $username
      password: $password
      confirmPassword: $confirmPassword
      email: $email
    ) {
      id
      username
      email
      token
    }
  }
`;
