import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "./context/theme";
import { AuthProvider } from "./context/auth";
import { ApolloProvider } from "@apollo/client";

import client from "./Apollo";
import "./styles.css";

import App from "./App";

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
