import React from "react";
import BlurbCarousel from "./BlurbCarousel";
import LandingCall from "./LandingCall";
import LogoSpot from "./LogoSpot";

export default function LandingPage() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <LogoSpot />
      <BlurbCarousel />
      <LandingCall />
    </div>
  );
}
