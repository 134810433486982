import React from "react";
import { motion } from "framer-motion";
import { ReactComponent as Logo } from "../../assets/largeLightLogo.svg";

export default function LogoSpot() {
  return (
    <div
      name="Logospot"
      style={{
        position: "relative",
        borderBottom: `2px solid #5A5A62`,
        width: "100%",
      }}
    >
      <div
        name="Ember Container"
        style={{
          background:
            "radial-gradient(circle at 50% 125%, rgba(71,32,46,1) 0%, rgba(35,35,46,1) 50%, rgba(0,0,0,1) 125%)",
          display: "flex",
          justifyContent: "center",
          height: "80vh",
          width: "100%",
        }}
      >
        {/* <Particles
          width="100vw"
          params={{
            particles: {
              number: {
                value: 60,
                density: {
                  enable: false,
                  value_area: 800,
                },
              },
              color: {
                value: "#fff",
              },
              shape: {
                type: "image",
                stroke: {
                  width: 0,
                  color: "#FFF",
                },
                polygon: {
                  nb_sides: 5,
                },
                image: {
                  src: "emberParticle.png",
                  width: 200,
                  height: 200,
                },
              },
              opacity: {
                value: 0.5,
                random: true,
                anim: {
                  enable: false,
                  speed: 1,
                  opacity_min: 0.1,
                  sync: false,
                },
              },
              size: {
                value: 30,
                random: true,
                anim: {
                  enable: false,
                  speed: 40,
                  size_min: 0.1,
                  sync: false,
                },
              },
              line_linked: {
                enable: false,
                distance: 500,
                color: "#ffffff",
                opacity: 0.4,
                width: 2,
              },
              move: {
                enable: true,
                speed: 2,
                direction: "top",
                random: true,
                straight: false,
                out_mode: "out",
                bounce: false,
                attract: {
                  enable: false,
                  rotateX: 600,
                  rotateY: 1200,
                },
              },
            },
            interactivity: {
              detect_on: "canvas",
              events: {
                onhover: {
                  enable: false,
                  mode: "bubble",
                },
                onclick: {
                  enable: false,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                grab: {
                  distance: 400,
                  line_linked: {
                    opacity: 0.5,
                  },
                },
                bubble: {
                  distance: 400,
                  size: 4,
                  duration: 0.3,
                  opacity: 1,
                  speed: 3,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
                push: {
                  particles_nb: 4,
                },
                remove: {
                  particles_nb: 2,
                },
              },
            },
            retina_detect: true,
          }}
        /> */}
      </div>
      <div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 3 }}
          style={{ alignContent: "center" }}
        >
          <Logo
            style={{
              position: "absolute",
              top: "42%",
              left: "50%",
              transform: "translate(-50%, -40%)",
              maxWidth: 850,
              width: "75%",
              minWidth: 350,
              maxHeight: "100%",
            }}
          />
        </motion.div>
      </div>
    </div>
  );
}
