import React, { useContext } from "react";
import styled from "styled-components";

import { ThemeContext } from "../../context/theme";

const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputLabel = styled.label`
  color: white;
  text-decoration: none;
  font-size: 16;
  font-family: Spectral SC;
  font-weight: 500;
`;

const InputBox = styled.input`
  color: white;
  background: transparent;
  border-radius: 5px;
  border: 2px solid ${({ theme }) => theme.secondary};
  padding: 5px;
  margin-top: 3px;
  min-width: 200px;

  &::placeholder {
    color: ${({ theme }) => theme.secondary};
    font-style: italic;
    opacity: 100%;
  }
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.primary};
  padding: 5px;
  margin-top: 3px;
  min-width: 200px;
  width: 100%;
`;

export default function TextInput(props) {
  const { label, placeholder, type, name, onChange, value } = props;
  let { errors } = props;
  errors = errors ? errors : [];

  const theme = useContext(ThemeContext);

  const errorList = errors.map((err) => {
    return <ErrorMessage theme={theme}>{err}</ErrorMessage>;
  });

  return (
    <InputDiv>
      <InputLabel>{label}</InputLabel>
      <InputBox
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        theme={theme}
      />
      {errorList}
    </InputDiv>
  );
}
