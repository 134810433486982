import React, { useContext } from "react";
import styled from "styled-components";
import { ThemeContext } from "../context/theme";
import { SetDefaultValue } from "../util/Functions";

const StyledButton = styled.button`
  background: ${({ theme }) => theme.primary};
  border: none;
  color: white;
  font-family: "Spectral SC";
  font-weight: 500;
  font-size: ${({ fontSize }) => fontSize};
  cursor: pointer;
  border-top-left-radius: ${({ borderRadius }) => borderRadius};
  border-bottom-right-radius: ${({ borderRadius }) => borderRadius};
  padding-left: ${({ horizontalPadding }) => horizontalPadding};
  padding-right: ${({ horizontalPadding }) => horizontalPadding};
  padding-top: ${({ verticalPadding }) => verticalPadding};
  padding-bottom: ${({ verticalPadding }) => verticalPadding};
`;

const Button = (props) => {
  let { fontSize, borderRadius, horizontalPadding, verticalPadding, onClick } =
    props;

  fontSize = SetDefaultValue(fontSize, "18px");
  borderRadius = SetDefaultValue(borderRadius, "15px");
  horizontalPadding = SetDefaultValue(horizontalPadding, "30px");
  verticalPadding = SetDefaultValue(verticalPadding, "3px");

  const theme = useContext(ThemeContext);
  return (
    <StyledButton
      theme={theme}
      fontSize={fontSize}
      borderRadius={borderRadius}
      horizontalPadding={horizontalPadding}
      verticalPadding={verticalPadding}
      onClick={onClick}
    >
      {props.children}
    </StyledButton>
  );
};

export default Button;
